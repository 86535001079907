


















import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class SurveyScreen extends Vue {
  @Prop({ default: 'transparent' })
  color!: String;

  @Prop({ default: null })
  image!: String;
}
