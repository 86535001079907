import { render, staticRenderFns } from "./ResultsOverview.vue?vue&type=template&id=14825d0b&"
import script from "./ResultsOverview.vue?vue&type=script&lang=ts&"
export * from "./ResultsOverview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSparkline } from 'vuetify/lib/components/VSparkline';
installComponents(component, {VCard,VCol,VContainer,VProgressCircular,VRow,VSheet,VSimpleTable,VSparkline})
