






























































import Vue from 'vue';
import { SurveyTheme, Settings } from '../../models';

const defaultSettings = { preset: 'Rating (5 levels)', size: 44, icon: 'mdi-star', required: false, horizontal: true };

const defaultSizes = [
  { text: 'Small', value: 36 },
  { text: 'Medium', value: 44 },
  { text: 'Large', value: 52 },
];

const defaultIcons = [
  { text: 'Star', value: 'mdi-star' },
  { text: 'Heart', value: 'mdi-heart' },
  { text: 'Thumb', value: 'mdi-thumb-up' },
  { text: 'Happy', value: 'mdi-emoticon-happy' },
  { text: 'Shield', value: 'mdi-shield' },
];

const defaultPresets = [
  {
    text: 'Rating (5 levels)',
    value: [
      { title: 'Lowest', value: 1, color: '#B71C1C' },
      { title: null, value: 2, color: '#F44336' },
      { title: null, value: 3, color: '#FFC107' },
      { title: null, value: 4, color: '#4CAF50' },
      { title: 'Highest', value: 5, color: '#2E7D32' },
    ],
  },
  {
    text: 'Rating (7 levels)',
    value: [
      { title: 'Lowest', value: 1, color: '#B71C1C' },
      { title: null, value: 2, color: '#D32F2F' },
      { title: null, value: 3, color: '#F44336' },
      { title: null, value: 4, color: '#FFC107' },
      { title: null, value: 5, color: '#4CAF50' },
      { title: null, value: 6, color: '#388E3C' },
      { title: 'Highest', value: 7, color: '#2E7D32' },
    ],
  },
  {
    text: 'Rating (10 levels)',
    value: [
      { title: 'Lowest', value: 1, color: '#B71C1C' },
      { title: null, value: 2, color: '#C62828' },
      { title: null, value: 3, color: '#D32F2F' },
      { title: null, value: 4, color: '#E53935' },
      { title: null, value: 5, color: '#F44336' },
      { title: null, value: 6, color: '#FFC107' },
      { title: null, value: 7, color: '#43A047' },
      { title: null, value: 8, color: '#388E3C' },
      { title: null, value: 9, color: '#2E7D32' },
      { title: 'Highest', value: 10, color: '#1B5E20' },
    ],
  },
];

export default Vue.extend({
  name: 'RatingItem',

  props: {
    theme: { type: Object as () => SurveyTheme, default: null },
    configure: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Number, default: null },
    settings: { type: Object as () => Settings, default: () => defaultSettings },
  },

  data() {
    return {
      choices: null,
      currentChoice: this.$props.value,
      presets: defaultPresets,
      sizes: defaultSizes,
      icons: defaultIcons,
      internalValue: this.value,
      internalSettings: this.settings,
    };
  },

  watch: {
    internalValue: {
      immediate: true,
      deep: true,
      handler(value) {
        const choice = this.internalSettings.choices.find((p) => p.value === value);
        this.currentChoice = choice;
        this.$emit('update:value', value);
      },
    },
    internalSettings: {
      immediate: true,
      deep: true,
      handler(value) {
        this.$emit('update:settings', value);
      },
    },
  },
  methods: {
    getPreset(title) {
      let preset = this.presets.find((p) => p.text == title);
      if (!preset) preset = defaultPresets[0];
      this.applyPreset(preset);
      return preset;
    },
    applyPreset(preset) {
      this.choices = preset.value;
      this.internalSettings.choices = preset.value;
      this.internalSettings.preset = preset.text;
      //this.settings.preset = preset;
    },
  },
});
