var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.value},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('pre',[_vm._v(_vm._s(JSON.stringify(item, null, 4)))]),_c('tr',{key:item.num},[_c('td',[_c('v-avatar',{attrs:{"size":"24","color":"primary white--text"}},[_vm._v(_vm._s(item.num))])],1),_c('td',[_vm._v(_vm._s(item.question))]),_c('td',[(item.category)?_c('v-chip',{staticClass:"elevation-1",attrs:{"label":"","small":"","dark":"","color":"secondary"},domProps:{"textContent":_vm._s(item.category)}}):_vm._e()],1),_c('td',[_vm._v(_vm._s(item.answers.length))]),_c('td',{attrs:{"nowrap":""}},[(item.normalizedScore)?[_vm._v(" "+_vm._s(_vm.formatValue(item.normalizedScore, 1, true))+" "),_c('span',{staticClass:"grey--text font-weight-thin"},[_vm._v("/10")])]:[_vm._v("n/a")]],2),_c('td',[_vm._v(_vm._s(item.percentScore ? _vm.formatValue(item.percentScore) + '%' : 'n/a'))]),_c('td',{staticClass:"pr-0"},[(item.choices)?_c('v-layout',[_vm._l((item.choices),function(choice,cidx){return [_c('v-tooltip',{key:choice.value,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(choice.count > 0)?_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center font-weight-bold link",staticStyle:{"height":"32px"},style:({
                    backgroundColor: _vm.getChoiceColor(cidx, choice.color),
                    width: _vm.calculateChoiceWidth(choice.count, _vm.value[item.num - 1].count),
                  })},'div',attrs,false),on),[_vm._v(" "+_vm._s(choice.count)+" ")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"text-body-2"},[_c('strong',[_vm._v(_vm._s(choice.value)+" "+_vm._s(choice.title ? '(' + choice.title + ')' : null)+":")]),_vm._v(" "+_vm._s(choice.count)+" "+_vm._s(choice.count === 1 ? 'choice' : 'choices')+" ")])])]})],2):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }