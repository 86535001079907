










































































































/* eslint-disable */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import stats from 'stats-lite';
import dayjs from 'dayjs';
import { formatDate, getStatusColor } from '@/utils';
import {
  Survey,
  SurveyType,
  SurveyResponses,
  CategoryResults,
  QuestionResults,
  SurveyScore,
  Answer,
  Choice,
} from '../models';
import { defaultSurveyTypes } from '../defaults';
import { surveyService } from '../services';

import ResultsOverview from '../components/results/ResultsOverview.vue';
import CategoriesList from '../components/results/CategoriesList.vue';
import QuestionsList from '../components/results/QuestionsList.vue';
import ResponsesList from '../components/results/ResponsesList.vue';

@Component({ components: { ResultsOverview, CategoriesList, QuestionsList, ResponsesList } })
export default class SurveyResultsView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Survey | null = null;
  responses: SurveyResponses = [];
  scoreMatrix: number[][] = [];
  categories: string[] = [];
  categoryResults: CategoryResults = {};
  questionResults: QuestionResults = [];
  dailyScore: Record<string, number> = {};
  totalScore = 0;
  totalScorePercent = 0;
  score: SurveyScore = {
    labels: [],
    values: [],
  };
  startDate = 0;
  startDateFmt = '';
  endDate = Date.now();
  endDateFmt = '';
  dateRange: string[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get dateRangeText(): string {
    return this.dateRange.join(' to ');
  }
  get surveyType(): SurveyType {
    return defaultSurveyTypes[this.survey!.type];
  }

  @Watch('id', { immediate: true })
  onIdChange(id) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('survey', surveyService.getSurveyRef(id));
  }

  @Watch('survey', { deep: true })
  onSurveyChange(survey) {
    if (survey) {
      document.title = survey.title + ' Results';
      this.$analytics.logEvent('survey-results', { survey_id: survey.id, user_id: this.userId });
      let categories = [] as string[];
      survey.questions!.forEach((q) => {
        if (q.category && !categories.includes(q.category.title)) categories.push(q.category.title);
      });
      this.categories = categories.sort();
      this.$bus.$emit('loading-indicator', false);

      this.startDate = survey.startedOn || survey.createdOn;
      this.startDateFmt = this.formatDate(this.startDate, 'YYYY-MM-DD')!;
      this.endDate = survey.completedOn || Date.now();
      this.endDateFmt = this.formatDate(this.endDate, 'YYYY-MM-DD')!;
      this.dateRange = [this.startDateFmt, this.endDateFmt];
    }
  }

  @Watch('dateRange', { immediate: true })
  async onDateRangeChange(value) {
    if (value == null || value.length < 2) return;
    this.startDateFmt = value[0];
    this.startDate = dayjs(this.startDateFmt + ' 00:00:00').valueOf();
    this.endDateFmt = value[1];
    this.endDate = dayjs(this.endDateFmt + ' 23:59:59').valueOf();
    //console.log('Data range', value, this.startDateFmt, this.endDateFmt, this.startDate, this.endDate);
    await this.$bind(
      'responses',
      surveyService
        .getResponsesRef(this.$props.id)
        .where('completedOn', '>=', this.startDate)
        .where('completedOn', '<=', this.endDate)
        .orderBy('completedOn', 'desc')
    );
    //console.log('Responses', formatDate(this.startDate), formatDate(this.endDate), this.responses);
    this.calculateScores();
    //console.log('Scores', this.questionResults);
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
  }

  formatDate = formatDate;
  getStatusColor = getStatusColor;

  normalize(value: number | null, min: number, max: number, weight = 1, scale = 10): number | null {
    return value === null ? null : ((value - min) / (max - min)) * weight * scale;
  }
  normalizePercent(value: number, weight = 1, scale = 10): number {
    return (value / (weight * scale)) * 100;
  }
  calculateScores() {
    if (!this.survey || !this.responses) return;
    surveyService.calculateResults(this.survey, this.responses);
    return;
    // TODO: Refactor this code!!
    let questionsCount = 0; //this.survey!.questions!.length;
    const responsesCount = this.responses!.length;
    const categoryResults: CategoryResults = {};
    const questionResults: QuestionResults = [];
    const dailyScore = {};

    this.survey!.questions!.forEach((question, questionIndex) => {
      let questionScore;
      questionScore = questionResults[questionIndex] = {
        num: questionIndex + 1,
        question: question.title,
        category: question.category ? question.category : null,
        answers: [],
        values: [],
        normalizedValues: [],
        choices: [],
        count: 0,
        total: 0,
        min: 0,
        max: 0,
        size: 0,
        mean: 0,
        median: 0,
        mode: 0,
        range: 0,
        normalizedScore: 0,
        percentScore: 0,
      };
      //const questionScore = questionResults[questionIndex];

      // questionScore.choices = Array.from(question!.settings!.choices);
      //questionScore.choices = JSON.parse(JSON.stringify(question!.settings!.choices));
      questionScore.choices = JSON.parse(JSON.stringify(question.choices)); //JSON.parse(JSON.stringify(question!.settings!.choices));
      questionScore.choices.forEach((c) => (c.count = 0));
      questionScore.min = questionScore.choices[0].value as number;
      questionScore.max = questionScore.choices.slice(-1)[0].value as number;
      questionScore.size = questionScore.max - questionScore.min + 1;

      let categoryScore;

      if (['Rating', 'Choice', 'NPS', 'SingleChoice', 'MultipleChoice'].includes(question.type)) {
        if (question.category) {
          if (!categoryResults[question.category]) {
            categoryScore = categoryResults[question.category] = {
              category: question.category,
              questions: [],
              responses: [],
              answers: [],
              values: [],
              normalizedValues: [],
              choices: [],
              count: 0,
              total: 0,
              min: 0,
              max: 0,
              size: 0,
              mean: 0,
              median: 0,
              mode: 0,
              range: 0,
              normalizedScore: 0,
              percentScore: 0,
            };
            categoryScore.choices = JSON.parse(JSON.stringify(question!.settings!.choices));
            categoryScore.choices.forEach((c) => (c.count = 0));
          } else {
            categoryScore = categoryResults[question.category];
          }
        }

        this.responses!.forEach((response, responseIndex) => {
          if (response.questions[questionIndex]) {
            /*
              if (Array.isArray(response.questions[questionIndex].answer)) {
                //answer = response.questions[questionIndex].answer!['value'] as number;
                answer = 1;
                //response.questions[questionIndex].answer!.map(v => v.value) as number[];
              } else {
                if (response.questions[questionIndex].answer!['value']) {
                  console.log('Value', response.questions[questionIndex].answer);
                  answer = 2;
                } else {
                  answer = 0; //response.questions[questionIndex].answer as number;
                }
              }
              */
            let answer: Answer | null = null;
            let answerValue: number | number[] | null = null;
            let normalizedAnswerValue: number | null = null;
            if (response.questions[questionIndex].answer) {
              if (!Array.isArray(response.questions[questionIndex].answer)) {
                answer = [response.questions[questionIndex].answer as Choice];
                answerValue = answer[0].value as number;
                if (['Rating', 'Choice', 'NPS'].includes(question.type)) {
                  normalizedAnswerValue = this.normalize(answerValue, questionScore.min, questionScore.max);
                }
                console.log('Answer', answer, answerValue, normalizedAnswerValue);
              } else {
                //answer = response.questions[questionIndex].answer as Choices;
                //answerValue = answer.map((a) => a.value) as number[];
                answer = null;
                answerValue = null;
              }
            }

            // Question bucket
            questionScore.count++;
            if (answer) {
              if (answerValue !== null) {
                questionScore.total += answer;
                questionScore.answers.push(answer);
                questionScore.normalizedAnswers.push(normalizedAnswerValue);
              } else {
                questionScore.choices.push({ title: 'Skipped', value: null, color: '#99999966', count: 0 });
              }
              let matchingChoice = questionScore.choices.find((c: Choice) => c.title === (answer as Choice).title);
              console.log('Matching choice', matchingChoice);
              //if (matchingChoice) (answer as Choice).count!++;
              if (matchingChoice) matchingChoice.count++;
            }

            // Category bucket
            if (categoryScore) {
              categoryScore.min = questionScore.min;
              categoryScore.max = questionScore.max;
              categoryScore.size = questionScore.size;
              categoryScore.count++;
              categoryScore.total += answerValue;
              categoryScore.answers.push(answerValue);
              categoryScore.normalizedAnswers.push(normalizedAnswerValue);
              if (answer === null) {
                categoryScore.choices.push({ title: 'Skipped', value: null, color: '#99999966', count: 0 });
              }
              let choice = categoryScore.choices.find((c) => c.value === answerValue);
              if (choice) choice.count++;
              categoryScore.questionResponses.push(response.questions[questionIndex]);
              if (!categoryScore.questions.includes(question)) categoryScore.questions.push(question);
            }

            // Day bucket
            if (response.completedOn) {
              const periodLabel = this.formatDate(response.completedOn, 'YYYY-MM-DD')!;
              if (!dailyScore[periodLabel]) dailyScore[periodLabel] = [];
              if (!dailyScore[periodLabel][questionIndex])
                dailyScore[periodLabel][questionIndex] = {
                  answers: [],
                  normalizedAnswers: [],
                  normalizedScore: 0,
                  percentScore: 0,
                };
              dailyScore[periodLabel][questionIndex].answers.push(answerValue);
              dailyScore[periodLabel][questionIndex].normalizedAnswers.push(normalizedAnswerValue);
            }
          }
        });

        questionScore.range = Math.max(...questionScore.answers) - Math.min(...questionScore.answers);
        questionScore.mean = stats.mean(questionScore.answers);
        questionScore.median = stats.median(questionScore.answers);
        questionScore.mode = stats.mode(questionScore.answers);
        questionScore.normalizedScore = stats.mean(questionScore.normalizedAnswers);
        questionScore.percentScore = this.normalizePercent(questionScore.normalizedScore);

        if (categoryScore) {
          categoryScore.range = Math.max(...categoryScore.answers) - Math.min(...categoryScore.answers);
          categoryScore.mean = stats.mean(categoryScore.answers);
          categoryScore.median = stats.median(categoryScore.answers);
          categoryScore.mode = stats.mode(categoryScore.answers);
          categoryScore.normalizedScore = stats.mean(categoryScore.normalizedAnswers);
          categoryScore.percentScore = this.normalizePercent(categoryScore.normalizedScore);
        }

        questionsCount++;
      }
    });

    this.totalScorePercent = questionResults.reduce((total, value) => total + value.percentScore, 0) / questionsCount;
    this.totalScore = questionResults.reduce((total, value) => total + value.normalizedScore, 0) / questionsCount;
    this.categoryResults = categoryResults;
    this.questionResults = questionResults;

    // Create trend
    const startSurvey = this.startDate;
    const endSurvey = this.endDate;
    let prevNormalizedScore = 0;
    let period = dayjs(startSurvey);

    this.score = { labels: [], values: [] };

    do {
      // Calculate daily scores
      let periodLabel = period.format('YYYY-MM-DD');
      if (dailyScore[periodLabel]) {
        const periodTotalScore =
          dailyScore[periodLabel].reduce((total, value) => total + stats.mean(value.normalizedAnswers), 0) /
          questionsCount;
        dailyScore[periodLabel].normalizedScore = periodTotalScore;
        prevNormalizedScore = dailyScore[periodLabel].normalizedScore;
      } else {
        // No score for this day
        dailyScore[periodLabel] = {
          answers: [],
          normalizedAnswers: [],
          normalizedScore: 0, // prevNormalizedScore,
          percentScore: 0, // not used!
        };
      }
      // Include only days with score
      if (dailyScore[periodLabel].normalizedScore) {
        this.score.labels.push(period.format('DD-MMM'));
        this.score.values.push(dailyScore[periodLabel].normalizedScore);
      }
      period = period.add(1, 'day');
    } while (period.diff(endSurvey, 'day') <= 0);
  }
}
