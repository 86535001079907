











































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { SurveyTheme, Settings, Answer, Choices } from '../../models';

const defaultChoices = (): Choices => [
  { title: 'Choice 1', value: 0 },
  { title: 'Choice 2', value: 1 },
  { title: 'Choice 3', value: 2 },
];

const defaultSettings = (): Settings => ({
  size: 'medium',
  required: false,
  horizontal: false,
  choices: defaultChoices(),
});

const defaultSizes = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

@Component
export default class MultipleChoiceItem extends Vue {
  @Prop({ type: Object as () => SurveyTheme, default: null })
  theme!: SurveyTheme;

  @Prop({ type: Boolean, default: false })
  configure!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  @Prop({ type: Array as () => Answer, default: () => [] })
  value!: Answer;

  @Prop({ type: Object as () => Settings, default: defaultSettings })
  settings!: Settings;

  @Prop({ type: Array as () => Choices, default: defaultChoices })
  choices!: Choices;

  sizes = defaultSizes;
  internalValue = this.value || [];
  internalSettings = this.settings;
  internalChoices = this.choices;

  @Watch('internalSettings', { immediate: true, deep: true })
  onSettingsChange(value) {
    this.$emit('update:settings', value);
  }
  @Watch('internalChoices', { immediate: true, deep: true })
  onChoicesChange(value) {
    this.$emit('update:choices', value);
  }
  addChoice() {
    const count = this.internalChoices.length;
    this.internalChoices.push({ title: 'Choice ' + (count + 1), value: count });
  }
  deleteChoice(idx) {
    this.internalChoices.splice(idx, 1);
  }
}
