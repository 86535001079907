var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.choices && _vm.settings)?_c('v-container',{staticClass:"pa-0 ma-0"},[_vm._t("items",[(0)?_c('code',[_vm._v("Type: "+_vm._s(_vm.type)+", Value: "+_vm._s(_vm.internalValue))]):_vm._e(),(_vm.settings.horizontal)?_c('v-row',{attrs:{"align":"center"}},_vm._l((_vm.choices),function(choice,cidx){return _c('v-col',{key:cidx,style:({ color: _vm.theme.colors.text }),attrs:{"cols":"auto"}},[_vm._t("item",[_c('v-card',{staticClass:"pa-2 mr-2",class:[
              _vm.center ? 'text-center' : 'text-start',
              _vm.isSelected(cidx) ? _vm.theme.colors.highlight : null,
              _vm.settings.size ],style:({
              backgroundColor: _vm.theme.colors.background,
              color: _vm.isSelected(cidx) ? null : _vm.theme.colors.text,
            }),attrs:{"tile":"","flat":"","min-width":_vm.minWidth},on:{"click":function($event){return _vm.choiceSelected(choice, cidx)}}},[_vm._t("item-content",[_c('div',{domProps:{"innerHTML":_vm._s(choice.title)}})],{"choice":choice,"cidx":cidx,"selected":_vm.isSelected(cidx),"value":_vm.internalValue})],2)],{"choice":choice,"cidx":cidx,"selected":_vm.isSelected(cidx),"value":_vm.internalValue})],2)}),1):_c('v-row',{attrs:{"no-gutters":"","align":"center"}},_vm._l((_vm.choices),function(choice,cidx){return _c('v-col',{key:cidx,attrs:{"cols":"12"}},[_vm._t("item",[_c('v-card',{staticClass:"pa-2 mb-2",class:[
              _vm.center ? 'text-center' : 'text-start',
              _vm.isSelected(cidx) ? _vm.theme.colors.highlight : null,
              _vm.settings.size ],style:({
              backgroundColor: _vm.theme.colors.background,
              color: _vm.isSelected(cidx) ? null : _vm.theme.colors.text,
            }),attrs:{"tile":"","flat":""},on:{"click":function($event){return _vm.choiceSelected(choice, cidx)}}},[_vm._t("item-content",[_c('div',{domProps:{"innerHTML":_vm._s(choice.title)}})],{"choice":choice,"cidx":cidx,"selected":_vm.isSelected(cidx),"value":_vm.internalValue})],2)],{"choice":choice,"cidx":cidx,"selected":_vm.isSelected(cidx),"value":_vm.internalValue})],2)}),1)],{"choices":_vm.choices})],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }