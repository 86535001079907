
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Survey, User } from '../models';
import { surveyService } from '../services';

@Component
export default class SurveyCloneView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Survey | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('survey', surveyService.getSurveyRef(id));
  }

  @Watch('survey')
  async onSurveyChange(survey: Survey) {
    if (survey) {
      document.title = survey.title;
      this.$bus.$emit('loading-indicator', false);
      await this.cloneSurvey(survey);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
  }

  async cloneSurvey(survey: Survey) {
    try {
      const newSurvey = await surveyService.cloneSurvey(survey, this.user);
      this.$bus.$emit('snackbar-notify', 'Survey successfully cloned', 'success');
      this.$analytics.logEvent('survey-clone', {
        survey_id: survey.id,
        user_id: this.userId,
        new_survey_id: newSurvey.id,
      });
      this.$router.push({ name: 'surveys' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
