




















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatValue, calculatePercent } from '@/utils';
import { calculateChoiceWidth } from '../../utils';
import { CategoryResults, Questions, SurveyResponses } from '../../models';
import { surveyService } from '../../services';

@Component
export default class CategoriesList extends Vue {
  @Prop({ type: Array as () => Questions, default: () => [] })
  questions!: Questions;

  @Prop({ type: Array as () => SurveyResponses, default: () => [] })
  responses!: SurveyResponses;

  @Prop({ type: Object as () => CategoryResults, required: true })
  value!: CategoryResults;

  headers = [
    { text: 'Category', value: 'category', sortable: true },
    { text: 'Questions', value: 'questions', sortable: true },
    { text: 'Responses', value: 'responses', sortable: true },
    { text: 'Score', value: 'normalizedScore', sortable: true },
    { text: 'Percentage', value: 'percentScore', sortable: true },
    { text: 'Response Distribution', value: 'choices', sortable: false },
  ];

  formatValue = formatValue;
  calculatePercent = calculatePercent;
  calculateChoiceWidth = calculateChoiceWidth;

  get results() {
    if (!this.questions || !this.responses) return {};
    return surveyService.getResultsByCategory(this.questions, this.responses);
  }

  get items() {
    return Object.values(this.results).sort((a, b) => a.category.localeCompare(b.category));
  }
}
