














import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Answers, Question, Survey, SurveyPhase, SurveyResponse, SurveyTheme } from '../models';
import { defaultSurveyThemes } from '../defaults';
import { surveyService } from '../services';

import SurveyStart from '../components/survey/SurveyStart.vue';
import SurveyQuestion from '../components/survey/SurveyQuestion.vue';
import SurveyEnd from '../components/survey/SurveyEnd.vue';

@Component({ components: { SurveyStart, SurveyQuestion, SurveyEnd } })
export default class SurveyTakeView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Survey | null = null;
  response: SurveyResponse = { questions: [] };
  phase: SurveyPhase = 'start';
  questionIndex = 0;
  answers: Answers = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get theme(): SurveyTheme {
    return defaultSurveyThemes[this.survey!.theme];
  }
  get question(): Question | null {
    if (!this.survey || this.questionIndex === null) return null;
    return this.survey.questions[this.questionIndex];
  }

  @Watch('id', { immediate: true })
  onIdChange(id) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('survey', surveyService.getSurveyRef(id)).catch((error) =>
      this.$bus.$emit('snackbar-notify', error, 'error')
    );
  }

  @Watch('survey')
  onSurveyChange(survey) {
    if (survey) {
      document.title = survey.title;
      this.$analytics.logEvent('survey-take', { survey_id: survey.id, user_id: this.userId });
      this.$bus.$emit('loading-indicator', false);
    }
  }

  @Watch('$route.hash', { immediate: true })
  onHashChange(hash) {
    hash = hash.substring(1);
    if (hash) {
      if (hash === 'end') {
        this.phase = 'end';
      } else {
        const questionNumber = parseInt(hash);
        if (questionNumber) {
          this.phase = 'question';
          this.questionIndex = questionNumber - 1;
          if (!this.response.startedOn) this.response.startedOn = Date.now();
        }
      }
    } else {
      this.phase = 'start';
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');

    this.$bus.$off('survey-start').$on('survey-start', this.startSurvey);
    this.$bus.$off('survey-end').$on('survey-end', this.endSurvey);
  }

  // Event handlers
  startSurvey() {
    if (!this.survey) return;
    this.phase = 'question';
    this.questionIndex = 0;
    this.response.startedOn = Date.now();
    //this.answers = Array(this.survey.questions.length).fill(null); // Initialize all question answers with null values
    this.$router.push(`#${this.questionIndex + 1}`);
  }
  async endSurvey(answers) {
    if (!this.survey) return;
    //this.answers = answers;
    //this.answers.forEach((answer, qidx) => {
    this.survey!.questions.forEach((question, qidx) => {
      //const question = this.survey!.questions[qidx];
      const answer = answers[qidx] || null;
      /*
      if (answer && question.settings!['values'] === false) {
        if (Array.isArray(answer)) {
          answer.forEach((a) => a && delete a['value']);
        } else {
          delete answer['value'];
        }
      }
      */
      this.response.questions[qidx] = {
        title: question.title,
        answer,
      };
    });
    this.response.completedOn = Date.now();
    this.phase = 'end';
    console.log('Response:', this.response, answers);
    try {
      await surveyService.createResponse(this.survey.id, this.response);
      this.$bus.$emit('snackbar-notify', 'Survey successfully submitted', 'success');
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
