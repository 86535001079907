








































































































































































import Vue from 'vue';
import { SurveyTheme, Settings } from '../../models';

const defaultSettings = { preset: 'NPS (5 levels)', size: 'medium', required: false, horizontal: true };

const defaultSizes = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

const defaultPresets = [
  {
    text: 'NPS (5 levels)',
    value: [
      { title: 'Very Unlikely', value: 0, color: '#D32F2F' },
      { title: null, value: 1, color: '#E53935' },
      { title: null, value: 2, color: '#FF9800' },
      { title: null, value: 3, color: '#FFB300' },
      { title: 'Very Likely', value: 4, color: '#388E3C' },
    ],
  },
  {
    text: 'NPS (7 levels)',
    value: [
      { title: 'Very Unlikely', value: 0, color: '#B71C1C' },
      { title: null, value: 1, color: '#D32F2F' },
      { title: null, value: 2, color: '#F44336' },
      { title: null, value: 3, color: '#F57C00' },
      { title: null, value: 4, color: '#FFB300' },
      { title: null, value: 5, color: '#4CAF50' },
      { title: 'Very Likely', value: 6, color: '#388E3C' },
    ],
  },
  {
    text: 'NPS (11 levels)',
    value: [
      { title: 'Very Unlikely', value: 0, color: '#B71C1C' },
      { title: null, value: 1, color: '#C62828' },
      { title: null, value: 2, color: '#D32F2F' },
      { title: null, value: 3, color: '#E53935' },
      { title: null, value: 4, color: '#F44336' },
      { title: null, value: 5, color: '#F57C00' },
      { title: null, value: 6, color: '#FF9800' },
      { title: null, value: 7, color: '#FFB300' },
      { title: null, value: 8, color: '#FFC107' },
      { title: null, value: 9, color: '#4CAF50' },
      { title: 'Very Likely', value: 10, color: '#388E3C' },
    ],
  },
];

export default Vue.extend({
  name: 'NPSItem',

  props: {
    theme: { type: Object as () => SurveyTheme, default: null },
    configure: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Number, default: null },
    settings: { type: Object as () => Settings, default: () => defaultSettings },
  },

  data() {
    return {
      choices: null,
      currentChoice: this.$props.value,
      sizes: defaultSizes,
      presets: defaultPresets,
      internalValue: this.value,
      internalSettings: this.settings,
    };
  },

  watch: {
    internalValue: {
      immediate: true,
      handler(value) {
        const choice = this.settings.choices && this.settings.choices.find((p) => p.value === value);
        this.currentChoice = choice;
        this.$emit('update:value', value);
      },
    },
    internalSettings: {
      immediate: true,
      handler(value) {
        this.$emit('update:settings', value);
      },
      deep: true,
    },
  },

  methods: {
    getPreset(title) {
      const preset = this.presets.find((p) => p.text == title);
      this.applyPreset(preset);
      return preset;
    },
    applyPreset(preset) {
      this.choices = preset.value;
      this.internalSettings.choices = preset.value;
      this.internalSettings.preset = preset.text;
      //this.settings.preset = preset;
    },
    selectChoice(choice) {
      if (this.currentChoice === choice && !this.internalSettings.required) {
        this.currentChoice = null;
      } else {
        this.currentChoice = choice;
      }
      if (this.currentChoice) {
        this.$emit('update:value', this.currentChoice.value);
      } else {
        this.$emit('update:value', null);
      }
    },
  },
});
