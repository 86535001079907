































































import Vue from 'vue';
import { SurveyTheme, Settings } from '../../models';

export default Vue.extend({
  name: 'SliderItem',

  props: {
    theme: { type: Object as () => SurveyTheme, default: null },
    configure: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Number, default: null },
    settingsProp: {
      type: Object as () => Settings,
      default: () => {
        return { min: 0, max: 10, levels: 11, size: 'medium' };
      },
    },
  },

  data() {
    return {
      settings: this.$props.settingsProp,
      sizes: [
        { text: 'Small', value: 'small' },
        { text: 'Medium', value: 'medium' },
        { text: 'Large', value: 'large' },
      ],
    };
  },

  watch: {
    settings: {
      handler(value) {
        this.$emit('update:settings', value);
      },
      deep: true,
    },
  },
});
