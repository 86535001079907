


























































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Survey, SurveyTheme, SurveyType } from '../../models';
import { defaultSurveyTypes } from '../../defaults';
import SurveyScreen from './SurveyScreen.vue';

@Component({ components: { SurveyScreen } })
export default class SurveyStart extends Vue {
  @Prop({ required: true })
  survey!: Survey;

  @Prop({ required: true })
  theme!: SurveyTheme;

  get surveyType(): SurveyType {
    return defaultSurveyTypes[this.survey!.type];
  }
  get surveyDuration(): number {
    return Math.round(this.survey.questions.length / 3);
  }
}
