









































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { SurveyTheme, Settings, Answer, Choice, Choices } from '../../models';

@Component
export default class ItemView extends Vue {
  //@PropSync('value', { default: null })
  //internalValue!: Answer;
  @Prop({ default: null })
  value!: Answer;
  internalValue: Answer = this.value;

  @Prop({ type: String, default: '' })
  type!: string;

  @Prop({ type: Object as () => SurveyTheme, default: null })
  theme!: SurveyTheme;

  @Prop({ type: Object as () => Settings, default: {} })
  settings!: Settings;

  @Prop({ type: Array as () => Choices, default: [] })
  choices!: Choices;

  @Prop({ type: Boolean, default: false })
  multiple!: boolean;

  @Prop({ type: Boolean, default: true })
  nillable!: boolean;

  @Prop({ type: Boolean, default: false })
  readonly!: boolean;

  @Prop({ type: Boolean, default: false })
  center!: boolean;

  @Prop({ type: Number, default: 150 })
  minWidth!: number;

  created() {
    if (!this.internalValue) {
      this.internalValue = this.$props.multiple ? new Array(this.$props.choices.length).fill(null) : this.$props.value;
    }
  }

  isSelected(cidx: number): boolean {
    //console.log('isSelected', cidx, this.internalValue);
    return this.multiple ? this.internalValue[cidx] !== null : this.internalValue === this.choices[cidx];
  }

  get selectedCount() {
    return (this.internalValue as Choices).filter((v) => v !== null).length;
  }

  choiceSelected(choice: Choice, cidx: number) {
    //if (this.readonly) return;
    if (this.multiple) {
      if (
        this.isSelected(cidx) &&
        this.nillable &&
        (!this.settings.required || (this.settings.required && this.selectedCount > 1))
      ) {
        this.$set(this.internalValue, cidx, null);
      } else {
        this.$set(this.internalValue, cidx, choice);
      }
    } else {
      if (this.isSelected(cidx) && this.nillable && !this.settings.required) {
        this.$set(this, 'internalValue', null);
      } else {
        this.$set(this, 'internalValue', choice);
      }
    }
    this.$emit('update:value', this.internalValue);
    //console.log('Choice selected', choice, cidx, this.internalValue);
  }
}
