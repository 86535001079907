import { firestore } from 'firebase/app';
import { db } from '@/firebase';
import { getId, downloadJson } from '@/utils';
import { User } from '@/models';
import {
  Choice,
  Choices,
  Questions,
  QuestionResult,
  Survey,
  SurveyResponse,
  SurveyResponses,
  SurveyResponsesMap,
  SurveyResults,
  ResponseResults,
  CategoryResults,
} from './models';

export const surveyService = {
  // References
  getSurveysRef() {
    return db.collection('surveys');
  },
  getSurveyRef(surveyId: string) {
    return this.getSurveysRef().doc(surveyId);
  },
  getResponsesRef(surveyId: string) {
    return this.getSurveyRef(surveyId).collection('responses');
  },
  getResponseRef(surveyId: string, responseId: string) {
    return responseId ? this.getResponsesRef(surveyId).doc(responseId) : null;
  },
  getConfigRef() {
    return db.collection('admin').doc('surveys');
  },

  // Surveys
  getAllSurveys() {
    return this.getSurveysRef();
  },
  getOwnSurveys(userId: string) {
    return this.getSurveysRef().where('owner', '==', userId);
  },
  getDraftSurveys(userId: string) {
    return this.getOwnSurveys(userId).where('status', '==', 'Draft').orderBy('createdOn', 'desc');
  },
  getActiveSurveys(userId: string) {
    return this.getOwnSurveys(userId).where('status', '==', 'Active').orderBy('createdOn', 'desc');
  },
  getClosedSurveys(userId: string) {
    return this.getOwnSurveys(userId).where('status', '==', 'Closed').orderBy('createdOn', 'desc');
  },
  async createSurvey(survey: Survey) {
    await this.getSurveyRef(survey.id).set(survey, { merge: true });
  },
  async updateSurvey(surveyId: string, surveyData: firestore.UpdateData) {
    await this.getSurveyRef(surveyId).update(surveyData);
  },
  async deleteSurvey(surveyId: string) {
    await this.deleteAllResponses(surveyId);
    await this.getSurveyRef(surveyId)?.delete();
  },
  async cloneSurvey(survey: Survey, user: User): Promise<Survey> {
    //const newSurvey: Survey = { ...survey };
    const newSurvey: Survey = JSON.parse(JSON.stringify(survey));
    newSurvey.id = getId();
    newSurvey.status = 'Draft';
    newSurvey.owner = user.id;
    newSurvey.createdBy = user;
    newSurvey.createdOn = Date.now();
    newSurvey.updatedOn = null;
    newSurvey.startedOn = null;
    newSurvey.completedOn = null;
    await this.createSurvey(newSurvey);
    return newSurvey;
  },
  async exportSurvey(survey: Survey) {
    const surveyObject: Survey = JSON.parse(JSON.stringify(survey));
    surveyObject.responses = {} as SurveyResponsesMap;
    const responses = await this.getAllResponses(survey.id).get();
    for (const response of responses.docs) {
      const responseData = response.data() as SurveyResponse;
      responseData.id = response.id;
      surveyObject.responses[response.id] = responseData;
    }
    downloadJson(surveyObject, surveyObject.id);
  },
  importSurvey(file: File): Promise<Survey> {
    const reader = new FileReader();
    let content: string | ArrayBuffer | null = null;
    let survey: Survey | null = null;
    return new Promise((resolve, reject) => {
      reader.readAsText(file, 'UTF-8');
      reader.onload = (event) => {
        if (event && event.target && event.target.result) {
          content = event.target.result;
          try {
            survey = JSON.parse(content.toString());
            if (survey) {
              // TODO: Runtime type safety/checking!
              survey.id = getId(); // Remap survey id to avoid conflicts!
              resolve(survey);
            } else {
              reject('Survey content missing');
            }
          } catch (error) {
            reject('Survey content malformed');
          }
        }
      };
      reader.onerror = () => {
        reject('Survey import failed');
      };
    });
  },

  // Responses
  getAllResponses(surveyId: string) {
    return this.getResponsesRef(surveyId);
  },
  async createResponse(surveyId: string, response: SurveyResponse) {
    response.id = getId();
    await this.getResponseRef(surveyId, response.id)?.set(response, { merge: true });
  },
  async updateResponse(surveyId: string, responseId: string, responseData: firestore.UpdateData) {
    await this.getResponseRef(surveyId, responseId)?.update(responseData);
  },
  async deleteResponse(surveyId: string, responseId: string) {
    await this.getResponseRef(surveyId, responseId)?.delete();
  },
  async deleteAllResponses(surveyId: string) {
    /*
    const snapshot = await this.getResponsesRef(surveyId).get();
    return snapshot.docs.map(async (doc) => {
      //const response = doc.data() as SurveyResponse;
      await this.deleteResponse(surveyId, doc.id);
    });
    */
    const batch = db.batch();
    const snapshot = await this.getResponsesRef(surveyId).get();
    for (const doc of snapshot.docs) {
      batch.delete(doc.ref);
    }
    await batch.commit();
  },

  normalize(value: number | null, min: number, max: number, weight = 1, scale = 10): number | null {
    return value === null ? null : ((value - min) / (max - min)) * weight * scale;
  },
  normalizePercent(value: number, weight = 1, scale = 10): number {
    return (value / (weight * scale)) * 100;
  },

  getResultsByCategory(questions: Questions, responses: SurveyResponses): CategoryResults {
    const results: CategoryResults = {};
    questions.forEach((question, questionIndex) => {
      const category = question.category;
      if (category) {
        if (!results[category])
          results[category] = {
            category,
            questions: [],
            responses: [],
            answers: [],
            values: [],
            normalizedValues: [],
            choices: question.choices,
            count: 0,
            total: 0,
            min: 0,
            max: 0,
            size: 0,
            mean: 0,
            median: 0,
            mode: 0,
            range: 0,
            normalizedScore: 0,
            percentScore: 0,
          };
        results[category].questions.push(question);
      }
      responses.forEach((response) => {
        const responseQuestion = response.questions[questionIndex];
        if (responseQuestion) {
          const answers = Array.isArray(responseQuestion.answer)
            ? responseQuestion.answer
            : responseQuestion.answer
            ? [responseQuestion.answer]
            : null;
          if (answers) {
            if (category) {
              results[category].responses.push(responseQuestion);
            } else {
              //results['NoCategory'].responses.push(responseQuestion);
            }
          }
        }
      });
    });
    console.log('Category results', results);
    return results;
  },
  getResultsByQuestion(questions: Questions, responses: SurveyResponses) {
    const results = {};
    responses.forEach((response, responseIndex) => {
      //results[responseIndex] = { response, questions: [] };
      results[responseIndex] = { questions: [] };
      response.questions.forEach((question, questionIndex) => {
        const surveyQuestion = questions[questionIndex];
        const answers = Array.isArray(question.answer) ? question.answer : question.answer ? [question.answer] : null;
        results[responseIndex].questions[questionIndex] = { ...surveyQuestion, answers };
      });
    });
    console.log('Response results', results);
    return results;
  },
  getResultsByResponse(questions: Questions, responses: SurveyResponses): ResponseResults {
    const results: ResponseResults = [];
    responses.forEach((response, responseIndex) => {
      //results[responseIndex] = { response, questions: [] };
      results[responseIndex] = {
        num: responseIndex,
        questions: [],
        answers: [],
        values: [],
        normalizedValues: [],
        choices: [],
        count: 0,
        total: 0,
        min: 0,
        max: 0,
        size: 0,
        mean: 0,
        median: 0,
        mode: 0,
        range: 0,
        normalizedScore: 0,
        percentScore: 0,
      };
      response.questions.forEach((question, questionIndex) => {
        const surveyQuestion = questions[questionIndex];
        const answers = Array.isArray(question.answer) ? question.answer : question.answer ? [question.answer] : null;
        results[responseIndex].questions[questionIndex] = surveyQuestion;
        if (answers) results[responseIndex].answers[questionIndex] = answers;
      });
    });
    console.log('Response results', results);
    return results;
  },

  calculateResults(survey: Survey, responses: SurveyResponses) {
    const results: SurveyResults = {
      categoryResults: {},
      questionResults: [],
      responseResults: [],
    };
    // Initialize buckets
    survey.questions.forEach((question, questionIndex) => {
      const questionResult: QuestionResult = {
        num: questionIndex + 1,
        question: question.title,
        category: question.category || null,
        choices: question.choices || [],
        answers: [],
        values: [],
        normalizedValues: [],
        count: 0,
        total: 0,
        min: 0,
        max: 0,
        size: 0,
        mean: 0,
        median: 0,
        mode: 0,
        range: 0,
        normalizedScore: 0,
        percentScore: 0,
      };
      results.questionResults[questionIndex] = questionResult;
    });
    responses.forEach((response) => {
      response.questions.forEach((question, questionIndex) => {
        const surveyQuestion = survey.questions[questionIndex];
        const questionResult = results.questionResults[questionIndex];
        const questionResponse = question;
        //console.log('Question response', questionIndex, questionResponse);
        questionResult.count++;
        const questionAnswer = questionResponse.answer;
        if (questionAnswer) {
          //console.log('Answer', questionAnswer);
          const answerArray = Array.isArray(questionAnswer) ? questionAnswer : [questionAnswer];
          answerArray.forEach((answer, answerIndex) => {
            //console.log(answerIndex, surveyQuestion.choices[answerIndex], answer, surveyQuestion.choices);
            const choice = questionResult.choices[answerIndex];
            if (!choice.count) choice.count = 0;
            if (answer && answer.title === surveyQuestion.choices[answerIndex].title) {
              choice.count++;
            }
          });
          /*
          if (!Array.isArray(questionAnswer)) {
            const answerValue = (answer.value as number) || null;
            let normalizedAnswerValue: number | null = null;
            if (questionAnswer !== null) {
              questionResult.answers.push(questionAnswer);
              questionResult.values.push(answerValue);
              questionResult.total += answerValue || 0;
              if (['Rating', 'Choice', 'NPS'].includes(question.type)) {
                normalizedAnswerValue = this.normalize(answerValue, questionResult.min, questionResult.max);
              }
              questionResult.normalizedValues.push(normalizedAnswerValue);
            } else {
              questionResult.choices.push({ title: 'Skipped', value: null, color: '#99999966', count: 0 });
            }
            //if(answer && answer.title === question.title) answer.count
            //const answerValue = answer.value as number;
            //if (answerValue) questionResult.answers.push(answerValue);
          } else {
            const answer = questionAnswer as Choices;
            //const answerValue = (answer.map((a) => a.value) as number[]) || null;
            //let normalizedAnswerValue: number[] | null = null;
            if (questionAnswer !== null) {
              questionResult.answers.push(questionAnswer);
              //questionResult.values.push(answerValue);
              //questionResult.total += answerValue || 0;
              //if (['Rating', 'Choice', 'NPS'].includes(question.type)) {
              //  normalizedAnswerValue = this.normalize(answerValue, questionResult.min, questionResult.max);
              //}
              //questionResult.normalizedValues.push(normalizedAnswerValue);
            }
          }
          */
        } else {
          //console.log('Empty answer', questionResponse, questionResult);
          //questionResult.choices.push({ title: 'Skipped', value: null, color: '#99999966', count: 0 });
        }
      });
    });
    //console.log('Survey results', results);
    return results;
  },
};

export default { surveyService };
