




























































import Vue from 'vue';
//import { RatingItem, Theme } from '../../models';

export default Vue.extend({
  name: 'RankingItem',

  props: {
    value: { type: Number, default: null },
    configure: { type: Boolean, default: false },
    //data: { type: Object as () => RatingItem, default: null },
    //theme: { type: Object as () => Theme }
  },

  data() {
    return {
      sizes: [
        { text: 'Small', value: 5 },
        { text: 'Medium', value: 10 },
        { text: 'Large', value: 15 },
      ],
      colors: [
        { text: 'Amber', value: 'amber' },
        { text: 'Red', value: 'red' },
        { text: 'Green', value: 'green' },
        { text: 'Blue', value: 'blue' },
      ],
      labels: ['Strongly Disagree', 'Disagree', 'Neutral', 'Agree', 'Strongly Agree'],
      options: { min: -2, max: 2, color: 'amber', size: 10 },
    };
  },
});
