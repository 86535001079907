


































































import { Vue, Component } from 'vue-property-decorator';
import { Survey, User } from '../models';
import { surveyService } from '../services';
import SurveyList from '../components/survey/SurveyList.vue';

@Component({ components: { SurveyList } })
export default class SurveyDashboardView extends Vue {
  showImportDialog = false;
  importing = false;
  importFiles: File[] = [];

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  created() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
    document.title = 'AgileMate Surveys';
    this.$analytics.logEvent('survey-dashboard');

    this.$bind('draftSurveys', surveyService.getDraftSurveys(this.userId));
    this.$bind('activeSurveys', surveyService.getActiveSurveys(this.userId));
    this.$bind('closedSurveys', surveyService.getClosedSurveys(this.userId));

    this.$bus.$off('survey-clone').$on('survey-clone', this.cloneSurvey);
    this.$bus.$off('survey-export').$on('survey-export', this.exportSurvey);
    this.$bus.$off('survey-delete').$on('survey-delete', this.deleteSurvey);
  }

  async cloneSurvey(survey: Survey) {
    try {
      const newSurvey = await surveyService.cloneSurvey(survey, this.user);
      this.$bus.$emit('snackbar-notify', 'Survey successfully cloned', 'success');
      this.$analytics.logEvent('survey-clone', {
        survey_id: survey.id,
        user_id: this.userId,
        new_survey_id: newSurvey.id,
      });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async importSurvey() {
    try {
      for (let file of this.importFiles) {
        this.importing = true;
        const survey: Survey = await surveyService.importSurvey(file);
        const responses = Object.values(survey.responses || {});
        delete survey.responses;
        //console.log('Import survey', survey);
        await surveyService.createSurvey(survey);
        for (let response of responses) {
          await surveyService.createResponse(survey.id, response);
        }
        this.$bus.$emit('snackbar-notify', 'Survey successfully imported', 'success');
        this.showImportDialog = false;
        this.importFiles = [];
        this.$analytics.logEvent('survey-import', { survey_id: survey.id, user_id: this.userId });
      }
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
    this.importing = false;
  }
  async exportSurvey(survey: Survey) {
    try {
      await surveyService.exportSurvey(survey);
      this.$bus.$emit('snackbar-notify', 'Survey successfully exported', 'success');
      this.$analytics.logEvent('survey-export', { survey_id: survey.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteSurvey(survey: Survey) {
    try {
      await surveyService.deleteSurvey(survey.id);
      this.$bus.$emit('snackbar-notify', 'Survey successfully deleted', 'success');
      this.$analytics.logEvent('survey-delete', { survey_id: survey.id, user_id: this.userId });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
