
















import { Vue, Component, Prop, PropSync } from 'vue-property-decorator';
import { SurveyTypes } from '../models';
import { defaultSurveyType, defaultSurveyTypes } from '../defaults';

@Component
export default class TemplateSelector extends Vue {
  @Prop({ type: String, default: () => defaultSurveyType })
  value!: string;

  @Prop({ type: Object as () => SurveyTypes, default: () => defaultSurveyTypes })
  templates!: SurveyTypes;

  selectTemplate(templateId: string) {
    this.$emit('input', templateId);
  }
}
