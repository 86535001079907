var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.items},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{key:item.category},[_c('td',{staticClass:"text-body-1"},[_c('v-chip',{staticClass:"elevation-1",attrs:{"label":"","small":"","dark":"","color":"secondary"},domProps:{"textContent":_vm._s(item.category)}})],1),_c('td',[_vm._v(_vm._s(item.questions.length))]),_c('td',[_vm._v(_vm._s(item.responses.length))]),_c('td',{attrs:{"nowrap":""}},[(item.normalizedScore)?[_vm._v(" "+_vm._s(_vm.formatValue(item.normalizedScore, 1, true))+" "),_c('span',{staticClass:"grey--text font-weight-thin"},[_vm._v("/10")])]:[_vm._v("n/a")]],2),_c('td',[_vm._v(_vm._s(item.percentScore ? _vm.formatValue(item.percentScore) + '%' : 'n/a'))]),_c('td',{staticClass:"pr-0"},[(item.choices)?_c('v-layout',[_vm._l((item.choices),function(choice){return [_c('v-tooltip',{key:choice.title,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(choice.count > 0)?_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-center white--text font-weight-bold",staticStyle:{"cursor":"default","height":"32px"},style:([
                    {
                      'background-color': choice.color,
                      width: _vm.calculateChoiceWidth(choice.count, _vm.items[item.category].count),
                    } ])},'div',attrs,false),on),[_vm._v(" "+_vm._s(choice.count)+" ")]):_vm._e()]}}],null,true)},[_c('div',{staticClass:"text-body-2"},[_c('strong',[_vm._v(_vm._s(choice.value)+" "+_vm._s(choice.title ? '(' + choice.title + ')' : null)+":")]),_vm._v(" "+_vm._s(choice.count)+" "+_vm._s(choice.count === 1 ? 'choice' : 'choices')+" ")])])]})],2):_vm._e()],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }