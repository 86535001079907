










import { Vue, Component } from 'vue-property-decorator';
import { Survey, User } from '../models';
import { defaultSurvey } from '../defaults';
import { surveyService } from '../services';
import SurveyBuilder from '../components/survey/SurveyBuilder.vue';

@Component({ components: { SurveyBuilder } })
export default class SurveyCreateView extends Vue {
  survey: Survey = defaultSurvey(this.user);

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get user(): User {
    return this.$store.getters['user/user'];
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
    document.title = 'AgileMate New Survey';
  }

  async saveSurvey(survey: Survey) {
    try {
      survey.owner = this.user.id;
      survey.createdBy = this.user;
      survey.createdOn = Date.now();
      await surveyService.createSurvey(survey);
      //this.$bus.$emit('snackbar-notify', 'Survey successfully saved', 'success');
      this.$analytics.logEvent('survey-create', { survey_id: survey.id, user_id: this.userId });
      this.$router.push({ name: 'surveys' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
