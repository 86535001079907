











import { Component } from 'vue-property-decorator';
import ItemEdit from './ItemEdit.vue';

@Component({ components: { ItemEdit } })
export default class SingleChoiceItemEdit extends ItemEdit {}
