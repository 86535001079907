





















import { Component, Watch } from 'vue-property-decorator';
import ItemView from './ItemView.vue';

@Component({ components: { ItemView } })
export default class SingleChoiceItemView extends ItemView {
  @Watch('internalValue')
  onValueChange(value) {
    this.$emit('update:value', value);
  }
}
