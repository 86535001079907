








































































































































































import Vue from 'vue';
import { SurveyTheme, Settings } from '../../models';

const defaultSettings = { preset: 'Opinion (5 levels)', size: 'medium', required: false, horizontal: true };

const defaultSizes = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

const defaultPresets = [
  {
    text: 'Opinion (3 levels)',
    value: [
      { title: 'Disagree', value: -1, color: '#F44336' },
      { title: 'Neutral', value: 0, color: '#FFC107' },
      { title: 'Agree', value: 1, color: '#4CAF50' },
    ],
  },
  {
    text: 'Opinion (5 levels)',
    value: [
      { title: 'Strongly Disagree', value: -2, color: '#B71C1C' },
      { title: 'Disagree', value: -1, color: '#F44336' },
      { title: 'Neutral', value: 0, color: '#FFC107' },
      { title: 'Agree', value: 1, color: '#4CAF50' },
      { title: 'Strongly Agree', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Opinion (7 levels)',
    value: [
      { title: 'Strongly Disagree', value: -3, color: '#B71C1C' },
      { title: null, value: -2, color: '#D32F2F' },
      { title: null, value: -1, color: '#F44336' },
      { title: 'Neutral', value: 0, color: '#FFC107' },
      { title: null, value: 1, color: '#4CAF50' },
      { title: null, value: 2, color: '#388E3C' },
      { title: 'Strongly Agree', value: 3, color: '#2E7D32' },
    ],
  },
  {
    text: 'Opinion (11 levels)',
    value: [
      { title: 'Strongly Disagree', value: -5, color: '#B71C1C' },
      { title: null, value: -4, color: '#C62828' },
      { title: null, value: -3, color: '#D32F2F' },
      { title: null, value: -2, color: '#E53935' },
      { title: null, value: -1, color: '#F44336' },
      { title: 'Neutral', value: 0, color: '#FFC107' },
      { title: null, value: 1, color: '#4CAF50' },
      { title: null, value: 2, color: '#43A047' },
      { title: null, value: 3, color: '#388E3C' },
      { title: null, value: 4, color: '#2E7D32' },
      { title: 'Strongly Agree', value: 5, color: '#1B5E20' },
    ],
  },
  {
    text: 'Frequency (5 levels)',
    value: [
      { title: 'Never', value: -2, color: '#B71C1C' },
      { title: 'Rarely', value: -1, color: '#F44336' },
      { title: 'Sometimes', value: 0, color: '#FFC107' },
      { title: 'Often', value: 1, color: '#4CAF50' },
      { title: 'Regularly', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Importance (5 levels)',
    value: [
      { title: 'Unimportant', value: -2, color: '#B71C1C' },
      { title: 'Slightly Important', value: -1, color: '#F44336' },
      { title: 'Moderately Important', value: 0, color: '#FFC107' },
      { title: 'Very Important', value: 1, color: '#4CAF50' },
      { title: 'Extremely Important', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Quality (5 levels)',
    value: [
      { title: 'Very Poor', value: -2, color: '#B71C1C' },
      { title: 'Poor', value: -1, color: '#F44336' },
      { title: 'Fair', value: 0, color: '#FFC107' },
      { title: 'Good', value: 1, color: '#4CAF50' },
      { title: 'Excellent', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Impact (5 levels)',
    value: [
      { title: 'Severe', value: -4, color: '#B71C1C' },
      { title: 'High', value: -3, color: '#F44336' },
      { title: 'Moderate', value: -2, color: '#FFC107' },
      { title: 'Low', value: -1, color: '#4CAF50' },
      { title: 'None', value: 0, color: '#2E7D32' },
    ],
  },
  {
    text: 'Approval (5 levels)',
    value: [
      { title: 'Strongly Disapprove', value: -2, color: '#B71C1C' },
      { title: 'Disapprove', value: -1, color: '#F44336' },
      { title: 'Neutral', value: 0, color: '#FFC107' },
      { title: 'Approve', value: 1, color: '#4CAF50' },
      { title: 'Strongly Approve', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Awareness (5 levels)',
    value: [
      { title: 'Unaware', value: -2, color: '#B71C1C' },
      { title: 'Slightly Aware', value: -1, color: '#F44336' },
      { title: 'Moderately Aware', value: 0, color: '#FFC107' },
      { title: 'Very Aware', value: 1, color: '#4CAF50' },
      { title: 'Extremely Aware', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Familiarity (5 levels)',
    value: [
      { title: 'Unfamiliar', value: -2, color: '#B71C1C' },
      { title: 'Slightly Familiar', value: -1, color: '#F44336' },
      { title: 'Moderately Familiar', value: 0, color: '#FFC107' },
      { title: 'Very Familiar', value: 1, color: '#4CAF50' },
      { title: 'Extremely Familiar', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Satisfaction (5 levels)',
    value: [
      { title: 'Dissatisfied', value: -2, color: '#B71C1C' },
      { title: 'Slightly Satisfied', value: -1, color: '#F44336' },
      { title: 'Moderately Satisfied', value: 0, color: '#FFC107' },
      { title: 'Very Satisfied', value: 1, color: '#4CAF50' },
      { title: 'Completely Satisfied', value: 2, color: '#2E7D32' },
    ],
  },
  {
    text: 'Expectation (5 levels)',
    value: [
      { title: 'Far Below Expectation', value: -2, color: '#B71C1C' },
      { title: 'Below Expectation', value: -1, color: '#F44336' },
      { title: 'Meets Expectation', value: 0, color: '#FFC107' },
      { title: 'Above Expectation', value: 1, color: '#4CAF50' },
      { title: 'Far Above Expectation', value: 2, color: '#2E7D32' },
    ],
  },
];

export default Vue.extend({
  name: 'ChoiceItem',

  props: {
    theme: { type: Object as () => SurveyTheme, default: null },
    configure: { type: Boolean, default: false },
    readonly: { type: Boolean, default: false },
    value: { type: Number, default: null },
    settings: { type: Object as () => Settings, default: () => defaultSettings },
  },

  data() {
    return {
      choices: null,
      currentChoice: this.$props.value,
      internalSettings: this.$props.settings,
      sizes: defaultSizes,
      presets: defaultPresets,
    };
  },

  watch: {
    value: {
      immediate: true,
      handler(value) {
        const choice = this.settings.choices && this.settings.choices.find((p) => p.value === value);
        this.currentChoice = choice;
      },
    },
    settings: {
      immediate: true,
      handler(settings) {
        this.internalSettings = settings;
      },
    },
    internalSettings: {
      deep: true,
      handler(value) {
        this.$emit('update:settings', value);
      },
    },
  },

  methods: {
    getPreset(title) {
      let preset = this.presets.find((p) => p.text == title);
      if (!preset) preset = defaultPresets[0];
      this.applyPreset(preset);
      return preset;
    },
    applyPreset(preset) {
      this.choices = preset.value;
      this.internalSettings.choices = preset.value;
      this.internalSettings.preset = preset.text;
      //this.settings.preset = preset;
    },
    selectChoice(choice) {
      if (this.currentChoice === choice && !this.internalSettings.required) {
        this.currentChoice = null;
      } else {
        this.currentChoice = choice;
      }
      if (this.currentChoice) {
        this.$emit('update:value', this.currentChoice.value);
      } else {
        this.$emit('update:value', null);
      }
    },
    isSelected(choice) {
      if (!this.currentChoice) return false;
      return choice.value === this.currentChoice.value;
    },
  },
});
