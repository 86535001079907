













import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Survey } from '../models';
import { surveyService } from '../services';

import SurveyBuilder from '../components/survey/SurveyBuilder.vue';

@Component({ components: { SurveyBuilder } })
export default class SurveyEditView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Survey | null = null;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get isOwner(): boolean | null {
    if (!this.survey) return null;
    return this.survey.owner === this.userId;
  }

  @Watch('id', { immediate: true })
  onIdChange(id: string) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('survey', surveyService.getSurveyRef(id));
  }

  @Watch('survey')
  onSurveyChange(survey: Survey) {
    if (survey) {
      document.title = survey.title;
      this.$bus.$emit('loading-indicator', false);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
  }

  async saveSurvey(survey: Survey) {
    try {
      survey.updatedOn = Date.now();
      await surveyService.updateSurvey(survey.id, survey);
      this.$bus.$emit('snackbar-notify', 'Survey successfully saved', 'success');
      this.$analytics.logEvent('survey-edit', { survey_id: survey.id, user_id: this.userId });
      //this.$router.push({ name: 'surveys' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
  async deleteSurvey(survey: Survey) {
    try {
      await surveyService.deleteSurvey(survey.id);
      this.$bus.$emit('snackbar-notify', 'Survey successfully deleted', 'success');
      this.$analytics.logEvent('survey-delete', { survey_id: survey.id, user_id: this.userId });
      this.$router.push({ name: 'surveys' });
    } catch (error) {
      this.$bus.$emit('snackbar-notify', error, 'error');
    }
  }
}
