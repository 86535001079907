
















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Survey, SurveyResponses, SurveyScore } from '../../models';

@Component
export default class ResultsOverview extends Vue {
  @Prop({ type: Object as () => Survey, required: true })
  survey!: Survey;

  @Prop({ type: Array as () => SurveyResponses, required: true })
  responses!: SurveyResponses;

  @Prop({ type: Object as () => SurveyScore, required: true })
  score!: SurveyScore;

  @Prop({ default: 0 })
  totalScore!: number;

  @Prop({ default: 0 })
  totalScorePercent!: number;

  get answersCount(): number {
    //return this.survey.questions.length * this.responses.length - 1;
    let count = 0;
    this.responses.forEach((r) =>
      r.questions.forEach((q) => {
        if (q.answer !== null) count++;
      })
    );
    return count;
  }

  scoreColor(score): string {
    if (score <= 2.5) {
      return 'red';
    } else if (score <= 5.0) {
      return 'amber';
    } else if (score <= 7.5) {
      return 'yellow';
    } else if (score <= 10) {
      return 'green';
    }
    return 'grey';
  }
  scorePercentColor(score): string {
    if (score <= 25) {
      return 'red';
    } else if (score <= 50) {
      return 'amber';
    } else if (score <= 75) {
      return 'yellow';
    } else if (score <= 100) {
      return 'green';
    }
    return 'grey';
  }
}
