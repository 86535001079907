






















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatValue } from '@/utils';
import { calculateChoiceWidth } from '../../utils';
import { QuestionResults, Questions, SurveyResponses } from '../../models';
import { surveyService } from '../../services';

@Component
export default class QuestionsList extends Vue {
  @Prop({ type: Array as () => Questions, default: () => [] })
  questions!: Questions;

  @Prop({ type: Array as () => SurveyResponses, default: () => [] })
  responses!: SurveyResponses;

  @Prop({ type: Array as () => QuestionResults, required: true })
  value!: QuestionResults;

  headers = [
    { text: null, value: 'num', width: '1%', align: 'center', sortable: false },
    { text: 'Question', value: 'question', sortable: true },
    { text: 'Category', value: 'category', sortable: true },
    { text: 'Responses', value: 'responses', sortable: true },
    { text: 'Score', value: 'normalizedScore', sortable: true },
    { text: 'Percentage', value: 'percentScore', sortable: true },
    { text: 'Response Distribution', value: 'choices', sortable: false },
  ];

  formatValue = formatValue;
  calculateChoiceWidth = calculateChoiceWidth;

  getChoiceColor(cidx: number, value: string): string {
    //const options = ['#D32F2F', '#E53935', '#FF9800', '#FFB300', '#388E3C'];
    const options = [
      '#2196F3',
      '#00BCD4',
      '#FFC107',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#009688',
      '#607D8B',
      '#795548',
      '#4CAF50',
    ];
    if (!value) return options[cidx % options.length];
    return value;
  }

  get results() {
    if (!this.questions || !this.responses) return [];
    return surveyService.getResultsByQuestion(this.questions, this.responses);
  }
}
