import { getId } from '@/utils';
import { Survey, SurveyTypes, SurveyThemes, ItemTypes, Categories, User } from './models';

export const defaultSurveyTypes: SurveyTypes = {
  StandardSurvey: {
    id: 'StandardSurvey',
    title: 'Standard Survey',
    description: 'Standard customizable survey (default)',
    icon: 'mdi-poll-box',
    color: 'indigo',
    style: 'background: linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%);',
    questionTypes: ['SingleChoice', 'MultipleChoice'],
  },
  PulseSurvey: {
    id: 'PulseSurvey',
    title: 'Pulse Survey',
    description: 'Pulse-type survey (standardized questions asked frequently)',
    icon: 'mdi-heart-pulse',
    color: 'pink',
    style: 'background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);',
    questionTypes: ['Choice', 'NPS'],
  },
  MoodCheck: {
    id: 'MoodCheck',
    title: 'Mood Check',
    description: 'Frequent team mood-checking survey',
    icon: 'mdi-emoticon',
    color: 'green',
    style: 'background: linear-gradient(90deg, #4f4bbb 0%, #a2e076 100%);',
    questionTypes: ['Choice', 'ImageChoice'],
  },
  EmployeeEngagement: {
    id: 'EmployeeEngagement',
    title: 'Employee Engagement',
    description: 'Survey for measuring employee engagement',
    icon: 'mdi-account-multiple-plus',
    color: 'orange',
    style: 'background: linear-gradient(90deg, #fcff9e 0%, #c67700 100%);',
    questionTypes: ['Choice', 'NPS'],
  },
  EmployeeSatisfaction: {
    id: 'EmployeeSatisfaction',
    title: 'Employee Satisfaction',
    description: 'Survey for measuring employee satisfaction',
    icon: 'mdi-emoticon-happy',
    color: 'deep-purple',
    style: 'background: linear-gradient(90deg, #4b6cb7 0%, #182848 100%);',
    questionTypes: ['Choice', 'NPS'],
  },
  ProductSatisfaction: {
    id: 'ProductSatisfaction',
    title: 'Product Satisfaction',
    description: 'Survey for measuring product satisfaction',
    icon: 'mdi-gift',
    color: 'brown',
    style: 'background: linear-gradient(90deg, #d53369 0%, #daae51 100%);',
    questionTypes: ['Choice', 'NPS'],
  },
};
export const defaultSurveyType = defaultSurveyTypes['StandardSurvey'];

export const defaultSurveyThemes: SurveyThemes = {
  Clean: {
    id: 'Clean',
    title: 'Clean',
    description: 'Clean theme (default)',
    colors: { text: '#000000', highlight: 'blue white--text', background: '#ffffff' },
  },
  CleanDark: {
    id: 'CleanDark',
    title: 'Clean Dark',
    description: 'Clean dark theme',
    colors: { text: '#ffffff', highlight: 'amber black--text', background: '#333333' },
  },
  Office: {
    id: 'Office',
    title: 'Office',
    description: 'Office theme',
    image:
      'https://images.pexels.com/photos/461077/pexels-photo-461077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'brown lighten-3 black--text', background: '#33333399' },
  },
  Team: {
    id: 'Team',
    title: 'Team',
    description: 'The Team collaboration theme',
    image:
      'https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'amber lighten-3 black--text', background: '#00000066' },
  },
  Matrix: {
    id: 'Matrix',
    title: 'Matrix',
    description: 'The Matrix theme',
    image:
      'https://images.pexels.com/photos/1089438/pexels-photo-1089438.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'green lighten-1 black--text', background: '#00000066' },
  },
  Code: {
    id: 'Code',
    title: 'Code',
    description: 'The Coders theme',
    image:
      'https://images.pexels.com/photos/97077/pexels-photo-97077.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'deep-purple lighten-2 black--text', background: '#ffffff99' },
  },
  Coffee: {
    id: 'Coffee',
    title: 'Coffee',
    description: 'The Coffee dream theme',
    image:
      'https://images.pexels.com/photos/982612/pexels-photo-982612.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#ffffff', highlight: 'brown lighten-1 white--text', background: '#00000066' },
  },
  Forest: {
    id: 'Forest',
    title: 'Forest',
    description: 'The Forest theme',
    image:
      'https://images.pexels.com/photos/1125776/pexels-photo-1125776.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'lime darken-4 white--text', background: '#cccccccc' },
  },
  Beach: {
    id: 'Beach',
    title: 'Beach',
    description: 'The Beach theme',
    image:
      'https://images.pexels.com/photos/994605/pexels-photo-994605.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'cyan lighten-4 black--text', background: '#ffffffcc' },
  },
  Trendy: {
    id: 'Trendy',
    title: 'Trendy',
    description: 'The Trendy theme',
    image:
      'https://images.pexels.com/photos/590041/pexels-photo-590041.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'orange black--text', background: '#ffffff99' },
  },
  Digital: {
    id: 'Digital',
    title: 'Digital',
    description: 'The Digital theme',
    image:
      'https://images.pexels.com/photos/373543/pexels-photo-373543.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'blue-grey darken-3 white--text', background: '#ffffffcc' },
  },
  Zen: {
    id: 'Zen',
    title: 'Zen',
    description: 'The Zen theme',
    image:
      'https://images.pexels.com/photos/312839/pexels-photo-312839.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    colors: { text: '#000000', highlight: 'blue-grey darken-4 white--text', background: '#ffffffcc' },
  },
};
export const defaultSurveyTheme = defaultSurveyThemes['CleanDark'];

export enum ItemTypeConstants {
  Choice = 'Choice',
  NPS = 'NPS',
  SingleChoice = 'SingleChoice',
  MultipleChoice = 'MultipleChoice',
  ImageChoice = 'ImageChoice',
  ShortText = 'ShortText',
  LongText = 'LongText',
  Rating = 'Rating',
  Slider = 'Slider',
  Ranking = 'Ranking',
  Heading = 'Heading',
  ImageBlock = 'ImageBlock',
  TextBlock = 'TextBlock',
  PageBreak = 'PageBreak',
}
export const defaultItemTypes: ItemTypes = {
  // Questions
  Choice: {
    id: 'Choice',
    class: 'ChoiceItem',
    title: 'Choice',
    icon: 'mdi-checkbox-marked',
  },
  NPS: {
    id: 'NPS',
    class: 'NPSItem',
    title: 'NPS',
    icon: 'mdi-gauge',
  },
  SingleChoice: {
    id: 'SingleChoice',
    class: 'SingleChoiceItem',
    title: 'Single Choice',
    icon: 'mdi-radiobox-marked',
  },
  MultipleChoice: {
    id: 'MultipleChoice',
    class: 'MultipleChoiceItem',
    title: 'Multiple Choice',
    icon: 'mdi-checkbox-multiple-marked',
  },
  ImageChoice: {
    id: 'ImageChoice',
    class: 'ImageChoiceItem',
    title: 'Image Choice',
    icon: 'mdi-image',
    disabled: true,
  },
  ShortText: {
    id: 'ShortText',
    class: 'ShortTextItem',
    title: 'Short Text',
    icon: 'mdi-text-short',
  },
  LongText: {
    id: 'LongText',
    class: 'LongTextItem',
    title: 'Long Text',
    icon: 'mdi-text-subject',
  },
  Rating: {
    id: 'Rating',
    class: 'RatingItem',
    title: 'Rating',
    icon: 'mdi-star',
  },
  Slider: {
    id: 'Slider',
    class: 'SliderItem',
    title: 'Slider',
    icon: 'mdi-pan-horizontal',
    disabled: true,
  },
  Ranking: {
    id: 'Ranking',
    class: 'RankingItem',
    title: 'Ranking',
    icon: 'mdi-order-numeric-ascending',
    disabled: true,
  },
  // Layout
  Heading: {
    id: 'Heading',
    class: 'HeadingItem',
    title: 'Heading',
    icon: 'mdi-format-header-1',
  },
  ImageBlock: {
    id: 'ImageBlock',
    class: 'ImageItem',
    title: 'Image',
    icon: 'mdi-format-wrap-square',
  },
  TextBlock: {
    id: 'TextBlock',
    class: 'TextItem',
    title: 'Custom Text',
    icon: 'mdi-format-text',
  },
  PageBreak: {
    id: 'PageBreak',
    class: 'PageBreakItem',
    title: 'Page Break',
    icon: 'mdi-format-page-break',
  },
};

export const defaultSurvey = (user: User): Survey => ({
  id: getId(),
  title: 'Survey',
  description: null,
  type: defaultSurveyType.id,
  theme: defaultSurveyTheme.id,
  status: 'Draft',
  owner: user.id,
  createdBy: user,
  createdOn: null,
  logo: '/img/logo.png',
  button: 'Start Survey',
  questions: [],
  entry: null,
  exit: null,
});
