import { render, staticRenderFns } from "./MultipleChoiceItemView.vue?vue&type=template&id=41a4e96c&"
import script from "./MultipleChoiceItemView.vue?vue&type=script&lang=ts&"
export * from "./MultipleChoiceItemView.vue?vue&type=script&lang=ts&"
import style0 from "./MultipleChoiceItemView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCol,VIcon,VRow})
