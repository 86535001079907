


























































































































































































































































































































































































































































































































































import { Vue, Component, PropSync, Watch } from 'vue-property-decorator';
import { Survey, Question, Questions, Categories, User, SurveyTheme, QuestionTypes } from '../../models';
import { defaultItemTypes, defaultSurvey, defaultSurveyTypes, defaultSurveyThemes } from '../../defaults';
import { surveyService } from '../../services';
import { getStatusColor } from '@/utils';

import TemplateSelector from '../TemplateSelector.vue';
import ThemeSelector from '../ThemeSelector.vue';

import ChoiceItemEdit from '../item/ChoiceItemEdit.vue';
//import ChoiceItemView from '../item/ChoiceItemView.vue';
import SingleChoiceItemEdit from '../item/SingleChoiceItemEdit.vue';
//import SingleChoiceItemView from '../item/SingleChoiceItemView.vue';
import MultipleChoiceItemEdit from '../item/MultipleChoiceItemEdit.vue';
//import MultipleChoiceItemView from '../item/MultipleChoiceItemView.vue';

/*
import ChoiceItem from '../items/items/ChoiceItem.vue';
import NPSItem from '../items/items/NPSItem.vue';
import SingleChoiceItem from '../items/items/SingleChoiceItem.vue';
import MultipleChoiceItem from '../items/items/MultipleChoiceItem.vue';
import ImageChoiceItem from '../items/items/ImageChoiceItem.vue';
import ShortTextItem from '../items/items/ShortTextItem.vue';
import LongTextItem from '../items/items/LongTextItem.vue';
import RatingItem from '../items/items/RatingItem.vue';
import SliderItem from '../items/items/SliderItem.vue';
import RangeItem from '../items/items/RangeItem.vue';
import RankingItem from '../items/items/RankingItem.vue';
import { defaultItemTypes } from '../../defaults';
*/

@Component({
  components: {
    TemplateSelector,
    ThemeSelector,
    ChoiceItemEdit,
    SingleChoiceItemEdit,
    MultipleChoiceItemEdit,
    /*
    ChoiceItem,
    NPSItem,
    SingleChoiceItem,
    MultipleChoiceItem,
    ImageChoiceItem,
    ShortTextItem,
    LongTextItem,
    RatingItem,
    SliderItem,
    RangeItem,
    RankingItem,
    */
  },
})
export default class SurveyBuilder extends Vue {
  @PropSync('value', { type: Object as () => Survey, required: true })
  survey!: Survey;

  surveyAdmin = {};
  surveyThemes = defaultSurveyThemes;
  itemTypes = defaultItemTypes;
  valid = false;
  step = 1;
  //entryPage = false;
  //exitPage = false;
  questionDbDialog = false;
  questionCategories: Categories = [];
  selectedCategories: Categories = [];
  questions: Questions = [];
  selectedQuestion: Question | null = null;

  get user(): User {
    return this.$store.getters['user/user'];
  }
  get questionTypes(): QuestionTypes {
    const itemTypes = defaultSurveyTypes[this.survey.type].questionTypes;
    const allItemTypes = Object.values(defaultItemTypes);
    return allItemTypes.filter((type) => itemTypes.includes(type.id));
  }
  get theme(): SurveyTheme {
    return defaultSurveyThemes[this.survey.theme];
  }
  get statusColor(): string {
    return getStatusColor(this.survey.status);
  }
  get allQuestions(): Questions {
    return this.surveyAdmin['questions'] || [];
  }
  get surveyQuestions(): Questions {
    return this.survey['questions'] || [];
  }
  get newQuestions(): Questions {
    return this.allQuestions.filter(
      (q: Question) => !this.surveyQuestions.map((q: Question) => q.title).includes(q.title)
    );
  }
  get entryPage(): boolean {
    return this.survey.entry !== null;
  }
  set entryPage(value) {
    this.survey.entry = this.survey.entry ? null : { title: '', description: '' };
  }
  get exitPage(): boolean {
    return this.survey.exit !== null;
  }
  set exitPage(value) {
    this.survey.exit = this.survey.exit ? null : { title: '', description: '' };
  }

  @Watch('surveyAdmin', { immediate: true })
  onConfigChange(surveyAdmin) {
    if (!surveyAdmin) return;
    if (surveyAdmin.questions && surveyAdmin.questions.length > 0) {
      this.questions = surveyAdmin.questions;
    }
    if (surveyAdmin.categories && surveyAdmin.categories.length > 0) {
      this.questionCategories = surveyAdmin.categories;
    }
  }

  created() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
    this.$bind('surveyAdmin', surveyService.getConfigRef());
  }

  gotoStep(step: number) {
    this.step = step;
  }
  createQuestion(questionType: string) {
    if (this.survey && this.survey.questions) {
      //const question: Question = { type: questionType, title: `Question ${this.survey.questions.length + 1}` };
      const question: Question = { title: 'Question', type: questionType, choices: [], settings: undefined };
      this.survey.questions.push(question);
    }
  }
  deleteQuestion(index: number) {
    if (this.survey && this.survey.questions.length > index) {
      this.survey.questions.splice(index, 1);
      this.$bus.$emit('notify-snackbar', 'Question successfully deleted');
    }
  }
  resetSurvey() {
    this.survey = defaultSurvey(this.user);
    this.$emit('change', this.survey);
    this.$bus.$emit('snackbar-notify', 'Survey reset', 'success');
  }
  saveSurvey() {
    this.$emit('save', this.survey);
  }
  deleteSurvey() {
    this.$emit('delete', this.survey);
  }
  setSurveyStatus(status: string) {
    this.survey.status = status;
    if (status === 'Active') {
      this.survey.completedOn = null;
      this.survey.startedOn = Date.now();
    } else if (status === 'Closed') {
      this.survey.completedOn = Date.now();
    }
    this.saveSurvey();
  }
  changeCategory(categories: Categories) {
    if (categories.length > 0) {
      this.questions = this.newQuestions.filter((q: Question) => q.category && categories.includes(q.category));
    } else if (this.surveyAdmin) {
      this.questions = this.newQuestions;
    }
  }
  addQuestion() {
    if (this.survey.questions && this.selectedQuestion) {
      // Prevent duplicate questions
      if (!this.survey.questions.find((q: Question) => q.title === this.selectedQuestion!['title'])) {
        this.survey.questions.push(this.selectedQuestion);
      }
      this.selectedQuestion = null;
    }
    //this.questionDbDialog = false;
  }
}
