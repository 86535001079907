















































































































import { Vue, Component, Prop, PropSync, Watch } from 'vue-property-decorator';
import { Question, Answer, Choice, Choices, Settings, SurveyTheme } from '../../models';
import { defaultItemTypes, defaultSurveyTheme } from '../../defaults';
import ItemView from './ItemView.vue';
import SingleChoiceItemView from './SingleChoiceItemView.vue';
import MultipleChoiceItemView from './MultipleChoiceItemView.vue';

const defaultSizes = [
  { text: 'Small', value: 'small' },
  { text: 'Medium', value: 'medium' },
  { text: 'Large', value: 'large' },
];

const defaultChoices = (): Choices => generateChoices(3);

const defaultSettings = (): Settings => ({
  size: 'medium',
  required: false,
  horizontal: false,
});

const defaultPresets = () => [
  { title: '2 Choices', value: generateChoices(2) },
  { title: '3 Choices', value: generateChoices(3) },
  { title: '4 Choices', value: generateChoices(4) },
  { title: '5 Choices', value: generateChoices(5) },
  { title: '6 Choices', value: generateChoices(6) },
  { title: '2 Choices with value', value: generateChoices(2, true) },
  { title: '3 Choices with value', value: generateChoices(3, true) },
  { title: '4 Choices with value', value: generateChoices(4, true) },
  { title: '5 Choices with value', value: generateChoices(5, true) },
  { title: '6 Choices with value', value: generateChoices(6, true) },
];

function generateChoices(count: number, hasValue: boolean = false): Choices {
  let choices: Choices = [];
  for (let i = 0; i < count; i++) {
    if (hasValue) {
      choices.push({ title: 'Choice ' + (i + 1), value: i });
    } else {
      choices.push({ title: 'Choice ' + (i + 1) });
    }
  }
  return choices;
}

@Component({ components: { ItemView, SingleChoiceItemView, MultipleChoiceItemView } })
export default class ItemEdit extends Vue {
  @PropSync('value', { default: null })
  internalValue!: Answer;

  @PropSync('choices', { type: Array as () => Choices, default: defaultChoices })
  internalChoices!: Choices;

  @PropSync('settings', { type: Object as () => Settings, default: null })
  internalSettings!: Settings;

  @Prop({ type: String, default: '' })
  type!: string;

  @Prop({ type: Object as () => SurveyTheme, default: defaultSurveyTheme })
  theme!: SurveyTheme;

  @Prop({ type: Object as () => Question, default: null })
  question!: Question;

  @Prop({ type: Boolean, default: false })
  multiple!: boolean;

  @Prop({ type: Boolean, default: true })
  nillable!: boolean;

  presets = defaultPresets();
  sizes = defaultSizes;
  questionTypes = defaultItemTypes;
  maxChoices = 6;
  currentChoice: Choice | null = null;
  showPreview = false;
  showSettings = true;

  get choiceCount() {
    return this.internalChoices.length;
  }
  get choiceLimitReached() {
    return this.choiceCount >= this.maxChoices;
  }
  get canDeleteChoice() {
    return this.choiceCount > 2;
  }

  created() {
    if (!this.internalChoices.length) {
      this.internalChoices = defaultChoices();
    }
    if (!this.internalSettings) {
      this.internalSettings = defaultSettings();
    }
  }

  addChoice() {
    const sequence = this.choiceCount + 1;
    if (sequence > this.maxChoices) return;
    const choice: Choice = { title: `Choice ${sequence}`, value: sequence - 1 };
    this.internalChoices.push(choice);
  }
  deleteChoice(cidx: number) {
    this.internalChoices.splice(cidx, 1);
  }

  onPresetChange(preset) {
    //console.log('Preset change', preset);
    this.internalChoices = preset;
  }
}
