





























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Survey, SurveyTheme } from '../../models';
import SurveyScreen from './SurveyScreen.vue';

@Component({ components: { SurveyScreen } })
export default class SurveyEnd extends Vue {
  @Prop({ required: true })
  survey!: Survey;

  @Prop({ required: true })
  theme!: SurveyTheme;
}
