






















































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { formatDate, formatInterval, calculatePercent } from '@/utils';
import { calculateChoiceWidth } from '../../utils';
import { Questions, SurveyResponses, Choice } from '../../models';
import { surveyService } from '../../services';

@Component
export default class ResponsesList extends Vue {
  @Prop({ type: Array as () => Questions, default: () => [] })
  questions!: Questions;

  @Prop({ type: Array as () => SurveyResponses, default: () => [] })
  responses!: SurveyResponses;

  headers = [
    { text: 'Started', value: 'startedOn', sortable: true },
    { text: 'Completed', value: 'completedOn' },
    { text: 'Duration', value: 'duration' },
    { text: 'Answers', value: 'answers' },
    { text: '', value: 'data-table-expand' },
  ];
  items = [];
  expanded: Record<string, unknown>[] = [];

  get results() {
    if (!this.questions || !this.responses) return [];
    return surveyService.getResultsByResponse(this.questions, this.responses);
  }

  created() {
    this.items = this.$props.responses.map((response, idx) => {
      return {
        idx: idx,
        startedOn: this.formatDate(response.startedOn, 'DD-MMM-YYYY HH:mm:ss'),
        completedOn: this.formatDate(response.completedOn, 'DD-MMM-YYYY HH:mm:ss'),
        duration: this.formatInterval(response.startedOn, response.completedOn, 'HH:mm:ss'),
        answers:
          this.answeredQuestions(response.questions) +
          '/' +
          response.questions.length +
          ' (' +
          this.calculatePercent(this.answeredQuestions(response.questions), response.questions.length) +
          '%)',
      };
    });
  }

  formatDate = formatDate;
  formatInterval = formatInterval;
  calculatePercent = calculatePercent;
  calculateChoiceWidth = calculateChoiceWidth;

  getChoice(qidx: number, choice: Choice): Choice | null {
    if (!this.questions[qidx] || !this.questions[qidx].choices) return null;
    return (
      this.questions[qidx].choices!.find((c) => (c.value && c.value === choice.value) || c.title === choice.title) ||
      null
    );
  }
  getChoiceIndex(qidx: number, choice: Choice): number | null {
    if (!this.questions[qidx] || !this.questions[qidx].choices) return null;
    const answerIndex = this.questions[qidx].choices!.findIndex(
      (c) => (c.value && c.value === choice.value) || c.title === choice.title
    );
    return answerIndex < 0 ? null : answerIndex;
  }
  getChoiceNumber(qidx: number, choice: Choice): number | null {
    const answerIndex = this.getChoiceIndex(qidx, choice);
    return answerIndex !== null ? answerIndex + 1 : null;
  }
  getChoiceColor(qidx: number, value: Choice): string {
    const options = [
      '#2196F3',
      '#00BCD4',
      '#FFC107',
      '#E91E63',
      '#9C27B0',
      '#673AB7',
      '#009688',
      '#607D8B',
      '#795548',
      '#4CAF50',
    ];
    const choice = this.getChoice(qidx, value);
    const cidx = this.getChoiceIndex(qidx, value) || 0;
    if (!choice || !choice.color) return options[cidx % options.length];
    return choice.color;
  }
  expandRow(value) {
    if (!this.expanded.includes(value)) {
      this.expanded.push(value);
    } else {
      this.expanded.splice(this.expanded.indexOf(value), 1);
    }
  }
  answeredQuestions(questions) {
    let count = 0;
    questions.forEach((q) => {
      if (q.answer !== null || (q.answer && q.answer.length > 0)) count++;
    });
    return count;
  }
}
