








































import Vue from 'vue';
//import { RatingItem, Theme } from '../../models';

export default Vue.extend({
  name: 'ShortTextItem',

  props: {
    value: { type: Number, default: null },
    configure: { type: Boolean, default: false },
    //data: { type: Object as () => RatingItem, default: null },
    //theme: { type: Object as () => Theme, default: null }
  },

  data() {
    return {
      sizes: [
        { text: 'Small', value: 48 },
        { text: 'Medium', value: 64 },
        { text: 'Large', value: 96 },
      ],
      lengths: [3, 4, 5, 6, 7, 8, 9, 10],
      colors: [
        { text: 'Amber', value: 'amber' },
        { text: 'Red', value: 'red' },
        { text: 'Green', value: 'green' },
        { text: 'Blue', value: 'blue' },
      ],
      icons: [
        { text: 'Star', value: 'mdi-star' },
        { text: 'Heart', value: 'mdi-heart' },
        { text: 'Thumb', value: 'mdi-thumb-up' },
        { text: 'Happy', value: 'mdi-emoticon-happy' },
        { text: 'Shield', value: 'mdi-shield' },
      ],
      options: { size: 64, length: 5, color: 'amber', icon: 'mdi-star' },
    };
  },
});
