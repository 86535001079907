





















































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Survey, Answers, SurveyResponse, SurveyTheme, SurveyType } from '../models';
import { defaultItemTypes, defaultSurveyThemes, defaultSurveyTypes } from '../defaults';
import { surveyService } from '../services';

import SingleChoiceItemView from '../components/item/SingleChoiceItemView.vue';
import MultipleChoiceItemView from '../components/item/MultipleChoiceItemView.vue';

import ChoiceItem from '../components/items/ChoiceItem.vue';
import NPSItem from '../components/items/NPSItem.vue';
import SingleChoiceItem from '../components/items/SingleChoiceItem.vue';
import MultipleChoiceItem from '../components/items/MultipleChoiceItem.vue';
import ImageChoiceItem from '../components/items/ImageChoiceItem.vue';
import ShortTextItem from '../components/items/ShortTextItem.vue';
import LongTextItem from '../components/items/LongTextItem.vue';
import RatingItem from '../components/items/RatingItem.vue';
import SliderItem from '../components/items/SliderItem.vue';
import RangeItem from '../components/items/RangeItem.vue';
import RankingItem from '../components/items/RankingItem.vue';

@Component({
  components: {
    SingleChoiceItemView,
    MultipleChoiceItemView,
    ChoiceItem,
    NPSItem,
    SingleChoiceItem,
    MultipleChoiceItem,
    ImageChoiceItem,
    ShortTextItem,
    LongTextItem,
    RatingItem,
    SliderItem,
    RangeItem,
    RankingItem,
  },
})
export default class SurveyPreviewView extends Vue {
  @Prop({ type: String, required: true })
  readonly id!: string;

  survey: Survey | null = null;
  response: SurveyResponse = { questions: [] };
  answers: Answers = [];
  questionIndex = 0;
  start = true;
  end = false;
  itemTypes = defaultItemTypes;

  get userId(): string {
    return this.$store.getters['user/userId'];
  }
  get theme(): SurveyTheme {
    return defaultSurveyThemes[this.survey!.theme];
  }
  get surveyType(): SurveyType {
    return defaultSurveyTypes[this.survey!.type];
  }
  get surveyDuration(): number {
    return this.survey && this.survey.questions ? Math.round(this.survey.questions.length / 3) : 0;
  }

  @Watch('id', { immediate: true })
  onIdChange(id) {
    this.$bus.$emit('loading-indicator', true);
    this.$bind('survey', surveyService.getSurveyRef(id)).catch((error) =>
      this.$bus.$emit('snackbar-notify', error, 'error')
    );
  }

  @Watch('survey')
  onSurveyChange(survey) {
    if (survey) {
      document.title = survey.title;
      this.$analytics.logEvent('survey-preview', { survey_id: survey.id, user_id: this.userId });
      this.answers = Array(survey.questions.length).fill(null);
      this.$bus.$emit('loading-indicator', false);
    }
  }

  mounted() {
    this.$bus.$emit('title-change', 'Surveys', '/surveys');
  }
}
